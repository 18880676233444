import React, {Fragment} from 'react';
import {Typography} from "@material-ui/core";

export default () => {

    return <Fragment>
        <Typography>
            Not Found
        </Typography>
    </Fragment>;
}
